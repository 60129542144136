var md = new MobileDetect(window.navigator.userAgent);
var ua = detect.parse(navigator.userAgent);
// console.log("mobile");
// console.log(md.mobile()); // 'Sony'
// console.log(md.phone()); // 'Sony'
// console.log(md.tablet()); // null
// console.log(md.userAgent()); // 'Safari'
// console.log(md.os()); // 'AndroidOS'
// console.log(md.is("iPhone")); // false
// console.log(md.is("bot")); // false
// console.log(md.version("Webkit")); // 534.3
// console.log(md.versionStr("Build")); // '4.1.A.0.562'
// console.log(md.match("playstation|xbox")); // false

// console.log("detect");
// console.log(ua.browser);
// console.log(ua.device);
// console.log(ua.os);
//test 2
var w = window,
    d = document,
    e = d.documentElement,
    g = d.getElementsByTagName("body")[0],
    x = w.innerWidth || e.clientWidth || g.clientWidth,
    y = w.innerHeight || e.clientHeight || g.clientHeight;

var ismobile = false;
var openMega = null;
var currentPage = 1;

if (md.mobile())
    ismobile = true;

// Define the Function targetBlank()
function targetBlank() {
    // remove subdomain of current site's url and setup regex
    var internal = location.host.replace("www.", "");
    internal = new RegExp(internal, "i");

    var a = document.getElementsByTagName('a'); // then, grab every link on the page
    for (var i = 0; i < a.length; i++) {
        var href = a[i].host; // set the host of each link
        if ((!internal.test(href) && !/^javascript/.test(a[i].getAttribute("href"))) || /\.pdf$/.test(a[i].getAttribute("href"))) { // make sure the href doesn't contain current site's host
            a[i].setAttribute('target', '_blank'); // if it doesn't, set attributes
        }
    }
}


(function () {
    "use strict";

    // Run the function targetBlank()
    targetBlank();

    document.querySelectorAll('.scroll-top')[0].addEventListener("click", function (event) {
        window.scrollTo({
            'behavior': 'smooth',
            'left': 0,
            'top': 0
        });
    });


    if (document.querySelectorAll("button.navbar-toggler").length > 0) {

        anime({
            targets: '.navbar-nav li',
            translateY: 200,
            opacity: 0,
            delay: anime.stagger(20)
        });
        // Mobile toggler
        document.querySelectorAll("button.navbar-toggler")[0].addEventListener("click", function (event) {
            var target = document.querySelectorAll('.navbar-collapse')[0];
            this.className = (this.className + " open").replace(/ open open/, "");
            target.className = (target.className + " show").replace(/ show show/, "");

            var darker = document.querySelectorAll('.content-darker')[0];
            if (target.classList.contains("show")) {
                darker.classList.add("show");
                setTimeout(function () {
                    anime({
                        targets: '.navbar-collapse li',
                        translateY: 0,
                        opacity: 1,
                        easing: 'easeOutQuad',
                        duration: 300,
                        delay: anime.stagger(60)
                    });
                }, 450);
            } else {
                anime({
                    targets: '.navbar-collapse li',
                    translateY: 200,
                    opacity: 0,
                    duration: 100,
                    delay: anime.stagger(0)
                });
                darker.classList.remove("show");
            }
        });
    }

    var video = document.querySelectorAll(".video")[0];
    if (video) {
        var iframe = document.querySelector('iframe');
        var player = new Vimeo.Player(iframe);

        player.on('play', function () {
            console.log('played the video!');
            video.classList.add("running");
            anime({
                targets: '.video .poster',
                opacity: 0,
                easing: 'easeInOutSine',
                duration: 300,
                delay: 3500
            });
            anime({
                targets: '.video iframe',
                opacity: 1,
                easing: 'easeInOutSine',
                duration: 0,
                delay: 3200
            });

            anime({
                targets: '.lds-dual-ring',
                opacity: 0,
                easing: 'easeInOutSine',
                duration: 250,
                delay: 2500
            }, '+=0');
        });

        player.on('ended', function () {
            console.log('played the ended!');
            video.classList.remove("running");
            anime({
                targets: '.video .poster',
                opacity: 1,
                easing: 'easeInOutSine',
                duration: 300
            });
            anime({
                targets: '.video iframe',
                opacity: 0,
                easing: 'easeInOutSine',
                duration: 300
            });

            // fadein main
            var tlinit = anime.timeline({
                easing: 'linear'
            });

            tlinit.add({
                targets: '.text-video .line1',
                opacity: 1,
                marginLeft: '0px',
                easing: 'easeInOutSine',
                duration: 450
            }, '+=600');
            tlinit.add({
                targets: '.text-video .line2',
                opacity: 1,
                marginLeft: '0px',
                easing: 'easeInOutSine',
                duration: 350
            }, '-=200');
            tlinit.add({
                targets: '.text-video .line3',
                opacity: 1,
                marginLeft: '0px',
                easing: 'easeInOutSine',
                duration: 550
            }, '+=0');
            tlinit.add({
                targets: '.play-button-outer',
                opacity: 1,
                easing: 'easeInOutSine',
                duration: 550
            }, '+=0');
        });



        if (document.querySelectorAll('.play-button-outer')[0]) {
            document.querySelectorAll('.play-button-outer')[0].addEventListener("click", function (event) {
                player.play();

                // fadein main
                var tlstart = anime.timeline({
                    easing: 'linear'
                });
                tlstart.add({
                    targets: '.play-button-outer',
                    opacity: 0,
                    easing: 'easeInOutSine',
                    duration: 550
                }, '+=0');
                tlstart.add({
                    targets: '.lds-dual-ring',
                    opacity: 1,
                    easing: 'easeInOutSine',
                    duration: 50
                }, '+=0');
                tlstart.add({
                    targets: '.text-video .line1',
                    opacity: 0,
                    marginLeft: '0px',
                    easing: 'easeInOutSine',
                    duration: 450
                }, '+=0');
                tlstart.add({
                    targets: '.text-video .line2',
                    opacity: 0,
                    marginLeft: '0px',
                    easing: 'easeInOutSine',
                    duration: 350
                }, '-=200');
                tlstart.add({
                    targets: '.text-video .line3',
                    opacity: 0,
                    marginLeft: '0px',
                    easing: 'easeInOutSine',
                    duration: 550
                }, '+=0');


            });

        }
    }
    // Mega Menu Darken
    document.querySelectorAll('.content-darker')[0].addEventListener("click", function (event) {

    });


    let image = null;
    // pdf preview
    document.querySelectorAll(".pdf-preview").forEach((btn) => {
        console.log(md.mobile());
        if (!md.mobile()) {
            btn.addEventListener('mouseenter', function (event) {
                if (this.getAttribute('data-preview')) {
                    image = document.createElement('img');
                    image.src = this.getAttribute('data-preview');
                    image.style.position = 'absolute';
                    image.style.zIndex = 100;
                    image.style.opacity = 0;
                    image.style.marginTop = '-20px';
                    image.style.top = event.clientY - this.clientY - 50 + "px";
                    // console.log(event);
                    image.style.left = event.clientX - 10 + "px";
                    this.appendChild(image);
                    image.onload = function () {
                        anime({
                            targets: image,
                            opacity: 1,
                            marginTop: '0px',
                            easing: 'easeInOutSine',
                            duration: 300
                        });
                    };
                }
            });
            btn.addEventListener('mousemove', function (event) {
                if (this.getAttribute('data-preview')) {
                    image.style.top = event.clientY - this.clientY - 50 + "px";
                    image.style.left = event.clientX - 10 + "px";
                }
            });
            btn.addEventListener('mouseleave', function (event) {
                if (this.getAttribute('data-preview')) {
                    image.remove();
                }
            });
        }
    });

    document.querySelectorAll(".table-row").forEach((btn) => {
        console.log(btn)
        if (btn.getAttribute('data-url')) {
            btn.addEventListener('click', function (event) {
                window.open(btn.getAttribute('data-url'), '_blank');
            });
        }
    });


    // Wait for Images
    imagesLoaded(document.querySelector("main"), function (instance) {

        anime({
            targets: '.revealup',
            clipPath: 'inset(0% 0px 0px 0px)',
            easing: 'easeInOutSine',
            duration: 300,
            delay: anime.stagger(400)
        });

        anime({
            targets: '.table .table-row',
            opacity: 1,
            marginTop: '0px',
            easing: 'easeInOutSine',
            duration: 300,
            delay: anime.stagger(100)
        });

        // fadein main
        var tlinit = anime.timeline({
            easing: 'linear'
        });

        tlinit.add({
            targets: '.lds-dual-ring',
            opacity: 0,
            easing: 'easeInOutSine',
            duration: 50
        }, '+=0');
        tlinit.add({
            targets: '.navbar',
            opacity: 1,
            easing: 'easeInOutSine',
            duration: 250
        }, '+=0');
        tlinit.add({
            targets: 'main',
            opacity: 1,
            easing: 'easeInOutSine',
            duration: 550
        }, '+=0');
        tlinit.add({
            targets: '.text-video .line1',
            opacity: 1,
            marginLeft: '0px',
            easing: 'easeInOutSine',
            duration: 450
        }, '+=0');
        tlinit.add({
            targets: '.text-video .line2',
            opacity: 1,
            marginLeft: '0px',
            easing: 'easeInOutSine',
            duration: 350
        }, '-=200');
        tlinit.add({
            targets: '.text-video .line3',
            opacity: 1,
            marginLeft: '0px',
            easing: 'easeInOutSine',
            duration: 550
        }, '+=0');
        tlinit.add({
            targets: '.play-button-outer',
            opacity: 1,
            easing: 'easeInOutSine',
            duration: 550
        }, '+=0');
    });
})();

// navbar shrink
window.addEventListener("scroll", function () {
    var nav = document.getElementById("mainNav");
    if (window.scrollY > 140) {
        nav.classList.add("shrink");
    } else {
        nav.classList.remove("shrink");
    }

    if (window.scrollY > 240) {
        document.querySelectorAll('.scroll-top')[0].classList.add('show');
    } else {
        document.querySelectorAll('.scroll-top')[0].classList.remove('show');
    }
});



// browser-warning
if (ua.browser.family === "IE") {
    const warning = document.querySelectorAll('.browser-warning');
    warning[0].className = 'browser-warning show';
}
